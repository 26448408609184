import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Button from "../components/Button"
import Cards from "../components/Cards"
import Carousel from "../components/Carousel"
import SiteMetadata from "../components/SiteMetadata"
import Layout from "../layouts/Layout"
import "./Grid.css"
export default props => {
  const {
    description,
    gallery,
    name,
    related,
    summary,
    thumbnail,
    url,
    columns,
  } = props.data.item

  console.log(props.data.item)
  return (
    <Layout>
      <SiteMetadata
        title={name}
        description={summary}
        image={thumbnail.localFile.publicURL}
      />
      <div className="bg-gray-0 py-12 lg:py-16">
        <div className="container">
          <div className="w-3/6 lg:pl-8 xl:pl-12">
            <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-1">
              {name}
            </h1>
            <h2 className="text-xl leading-tight font-semibold tracking-tight text-gray-600 sm:text-2xl">
              {summary}
            </h2>
            {description && (
              <div className="my-4 text-base text-gray-700 whitespace-pre-line">
                {description.description}
              </div>
            )}
          </div>

          <div className="gallery-row w-3/6 lg:w-2/3 lg:pl-8 xl:pl-12 mt-12">
            <div
              class={`grid grid-flow-row-dense grid-cols-${columns ??
                6} gap-8 w-full mx-10 items-center`}
            >
              {gallery &&
                gallery.map((image, index, images) => {
                  var nextImage = images[index + 1]

                  var isNextVertical =
                    nextImage != null
                      ? nextImage.localFile.childImageSharp.fluid.aspectRatio <
                        1
                      : false

                  return (
                    <Img
                      className={
                        image.localFile.childImageSharp.fluid.aspectRatio > 1
                          ? "col-span-6 col-start-auto"
                          : "col-span-3"
                      }
                      fluid={image.localFile.childImageSharp.fluid}
                      alt={name}
                    />
                  )
                })}
            </div>
          </div>
        </div>
      </div>
      {related && (
        <div className="bg-gray-100 py-12 lg:py-12">
          <div className="container">
            <h2 className="text-xl sm:text-xl leading-tight font-extrabold tracking-tight text-gray-900 mb-3">
              You may also like...
            </h2>
          </div>
          <Cards items={related} hideLastItemOnMobile={true} />
        </div>
      )}
      {/* <Newsletter /> */}
    </Layout>
  )

  return (
    <div className="gallery-row w-full lg:w-2/3 pb-8">
      <div className="gallery-column">
        {gallery &&
          gallery.length > 1 &&
          gallery.map(image => {
            return (
              <Img fluid={image.localFile.childImageSharp.fluid} alt={name} />
            )
          })}
      </div>
    </div>
  )
  return (
    <Layout>
      <SiteMetadata
        title={name}
        description={summary}
        image={thumbnail.localFile.publicURL}
      />
      <div className="bg-gray-0 py-12 lg:py-16">
        <div className="container">
          <div className="w-full lg:w-1/3 lg:pl-8 xl:pl-12">
            <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-1">
              {name}
            </h1>
            <h2 className="text-xl leading-tight font-semibold tracking-tight text-gray-600 sm:text-2xl">
              {summary}
            </h2>
            {description && (
              <div className="my-4 text-base text-gray-700 whitespace-pre-line">
                {description.description}
              </div>
            )}
          </div>

          <div className="gallery-row w-full lg:w-2/3 pb-8">
            <div className="gallery-column">
              {gallery &&
                gallery.length > 1 &&
                gallery.map(image => {
                  return (
                    <Img
                      fluid={image.localFile.childImageSharp.fluid}
                      alt={name}
                    />
                  )
                })}
            </div>
            <div className="gallery-column">
              {gallery &&
                gallery.length > 1 &&
                gallery.map(image => {
                  return (
                    <Img
                      fluid={image.localFile.childImageSharp.fluid}
                      alt={name}
                    />
                  )
                })}
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="w-full lg:w-2/3 pb-8">
              {gallery && gallery.length === 1 && (
                <Img
                  fluid={gallery[0].localFile.childImageSharp.fluid}
                  alt={name}
                />
              )}
              {gallery &&
                gallery.length > 1 &&
                gallery.map(image => {
                  return (
                    <Img
                      fluid={image.localFile.childImageSharp.fluid}
                      alt={name}
                    />
                  )
                })}
            </div>
            <div className="w-full lg:w-1/3 lg:pl-8 xl:pl-12">
              <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-1">
                {name}
              </h1>
              <h2 className="text-xl leading-tight font-semibold tracking-tight text-gray-600 sm:text-2xl">
                {summary}
              </h2>
              {description && (
                <div className="my-4 text-base text-gray-700 whitespace-pre-line">
                  {description.description}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {related && (
        <div className="bg-gray-100 py-12 lg:py-12">
          <div className="container">
            <h2 className="text-xl sm:text-xl leading-tight font-extrabold tracking-tight text-gray-900 mb-3">
              You may also like...
            </h2>
          </div>
          <Cards items={related} hideLastItemOnMobile={true} />
        </div>
      )}
      {/* <Newsletter /> */}
    </Layout>
  )
}

export const query = graphql`
  query PortfolioItemQUery($slug: String!) {
    item: contentfulPortfolio(slug: { eq: $slug }) {
      description {
        description
      }
      gallery {
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 960, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
      }
      name
      related {
        ...PortfolioCard
      }
      summary
      thumbnail {
        localFile {
          publicURL
        }
      }
      url
    }
  }
`
